<template>
    <div id="inquire">
        <div class="container">
            <div class="info">
                <h5>
                    {{$t('message.inquire.phone')}}
                </h5>
                <h5>
                     {{$t('message.inquire.email')}}
                </h5>
                <hr>
                <h5>
                    Sales Team Office:
                </h5>
                <p>
                    1417 Everett Street,
                </p>
                <p>
                    Alameda CA
                </p>
                <p>
                    94501
                </p>
                <br>
                <h5>
                    Management Team Office:
                </h5>
                <p>
                    2514 Santa Clara Avenue, 
                </p>
                <p>
                    Alameda CA 
                </p>
                <p>
                    94501
                </p>
            </div>
            <div class="map">
                <Map :marker="marker" :height="600"/>
            </div>
        </div>
    </div>
</template>

<script>
import Map from "../components/Map"
export default {
    name: "Inquire",
    components: {
        Map,
    },
    data(){
        return{
            team:[],
            marker: {
                lat: 37.763999, 
                lng: -122.240771,
            },
        }
    },
    metaInfo: {
        title: 'Contact',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    border-bottom: 1px solid #eee;
    .map{
        width: 60%;
    }
    .info{
        flex: 1;
        margin: 32px 64px;
        padding: 0 8%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h5{
            font-size: 1rem;
            margin: 16px 0;
        }
        hr{
            border: none;
            height: 1px;
            color: #000;
            background-color: #000;
            width: 100%;
            margin: 28px auto;
        }
        p{
            color: #000;
            margin: 0;
            line-height: 1.5rem;
        }
    }
}
@media (max-width: $mobile) {
    .container{
        flex-wrap: wrap;
        .map{
            width: 100%;
        }
        .info{
            width: 100%;
            margin: 32px;
            padding: 0;
        }
    }
}
</style>